<template>
  <div class="hero-body">
    <div class="container">
      <h1 class="title">
        Sorry, the page you are looking for doesn't exist. <br >
        <br >
        Redirecting...
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
  // props: [],
  mixins: [],
  data() {
    return {}
  },
  beforeCreate() {},
  created() {
    this.$router.push("/404")
    setTimeout(() => this.$router.push({ path: "/dashboard" }), 4000)
  },
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {},
  filters: {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped></style>
